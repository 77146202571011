@import url('https://fonts.googleapis.com/css?family=Cairo');

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

body { 
  background-color: #6e3c6c;

}


#button {
  appearance: none;  
  border: none;
  background-color: #F16666;
  padding: 10px;
  font-size: 20pt;
  position: absolute;
  bottom: 50px;
  border-radius: 10px;
  width: 200px;
  height: 50px;
  left: calc(50% - 100px);
  color: white;
}

h1 {  
  position: absolute;
  top: 20px;
  justify-content: center;
  align-self: center;
  display: flex;
  border-radius: 10px;
  width: 500px;
  height: 50px;
  left: calc(50% - 250px);
  text-align: center;
  color: white;
}


.icons { 
  display: inline-block;
  width: 150px;
  height: 564px;
  overflow: hidden;
  background: #fff url(./sprites.png) repeat-y;
  will-change: backgroundPosition;
  transition: 0.3s background-position ease-in-out;
  padding: 0 80px;
  transform: translateZ(0);
}

.icons:nth-child(2) {
  margin: 0 10px;
}

* { box-sizing: border-box; }

.spinner-container {
  overflow: hidden;
  height: 632px;  
  padding: 2em;
  transform: translate(-50%, -50%) scale(0.8, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  transition: 0.3s transform;
}

.app-wrap.winner-false {
  animation: linear 1s spin;
}

.spinner-container::after {
  position: absolute;
  content: '';
  display: block;
  height: 180px;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  left: 30px;
  background: rgba(255, 0, 0, .1);  
}

.gradient-fade {
  position: absolute;
  top: 32px;
  right: 32px;
  bottom: 32px;
  left: 32px;
  background: linear-gradient(
    to bottom, 
    rgba(64,64,64,1) 0%, 
    rgba(64,64,64,0) 7%, 
    rgba(64,64,64,0) 93%, 
    rgba(64,64,64,1) 100%
  );
}

@media screen and (max-width: 1000px) {
  .spinner-container {
    transform: translate(-50%, -50%) scale(.55, .55);
  }
}

@media screen and (max-width: 500px) {
  .spinner-container {
    transform: translate(-50%, -50%) scale(.4, .4);
  }
}

@keyframes move_eye { 100% { transform: rotate(360deg);  } }


.rotation{
    background-image: url("mosaic.png");
    background-size: contain;
    animation: 3s linear 0s infinite alternate move_eye;
    width: 100px;
    height: 100px;
    display: inline-block;
}
